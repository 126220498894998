import React from 'react'
import {Link} from 'react-router-dom'
import ab1 from '../../assets/images/about/about.jpeg'

// const ab1 = 'https://source.unsplash.com/qRAMxGJcrEk';

const About2 = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area-2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={ab1} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                                <span>About Us</span>
                                <h2>A Best Place to Enjoy <br/> Your Stay </h2>
                            </div>
                            <p>Essotto is your one stop hub for recreation. Enjoy your luxurious stay at the heart of the Silicon Valley of India along with a comprehensive selection of activities to keep you refreshed. Visit and explore us now. </p>
                            <div className="btns">
                                <Link onClick={ClickHandler} to="/about" className="theme-btn-s2">More About Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About2;