import React from 'react';
import SearchSection from './Rcomponents/select';
import Rooms from './Rcomponents/room';
import Description from './Rcomponents/description';
import './style.css';

const RoomSingleSection = ({ restdata }) => {
    console.log({ restdata });

    return (
        <div className="details-page">
            {/* {restdata.restdata.is_room_service ? <SearchSection /> : null} */}
            {/* {restdata.restdata.is_room_service ? <Rooms /> : null} */}
            <div style={{ marginTop: '20px' }}>
                {/* <Description
                    restdata={restdata.restdata}
                    menu_groups={restdata.pos_item_menugrps['dine-in']}
                /> */}
                <Description
                    restdata={restdata}
                    // menu_groups={restdata.pos_item_menugrps['dine-in']}
                />
            </div>
        </div>
    );
};

export default RoomSingleSection;
