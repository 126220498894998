import React from 'react';
import AllRoute from '../router'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { store } from '../../redux/store';
import { Provider } from 'react-redux';


const App = () => { 

  return (
      <div className="App" id="scrool">
          <Provider store={store}>
              <AllRoute />
              <ToastContainer />
          </Provider>
      </div>
  );
}

export default App;
