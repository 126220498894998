import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.png'
import ft1 from '../../images/footer/img-1.jpg'
import ft2 from '../../images/footer/img-2.jpg'
import './style.css'

const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="wpo-footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="logo" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                                <ul>
                                    <li>
                                        <Link
                                            to={{
                                                pathname:
                                                    'https://facebook.com/Essotto.Official',
                                            }}
                                            target="_blank"
                                        >
                                            <i className="ti-facebook"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={{
                                                pathname:
                                                    'https://twitter.com/Essotto_oficial',
                                            }}
                                            target="_blank"
                                        >
                                            <i className="ti-twitter-alt"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={{
                                                pathname:
                                                    'https://www.instagram.com/essotto.official/',
                                            }}
                                            target="_blank"
                                        >
                                            <i className="ti-instagram"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={{
                                                pathname:
                                                    'https://g.co/kgs/HBaUz8',
                                            }}
                                            target="_blank"
                                        >
                                            <i className="ti-google"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget market-widget wpo-service-link-widget">
                                    <div className="widget-title">
                                        <h3>Contact </h3>
                                    </div>
                                    <p>
                                        Life may not be perfect but your
                                        entertainment can be.
                                    </p>
                                    <div className="contact-ft">
                                        <ul>
                                            <li>
                                                <Link
                                                    to={{
                                                        pathname:
                                                            'https://www.google.com/maps/place/Essotto+Recreation+Hub/@12.9767836,77.7243486,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae15386493fe8d:0xc36eac3f36cb737d!8m2!3d12.9767836!4d77.7265373!16s%2Fg%2F11t9pg43wh',
                                                    }}
                                                    target="_blank"
                                                >
                                                    <i className="fi ti-location-pin"></i>
                                                    Essotto Recreation Hub, ITPL
                                                    Main Bus Stand, Whitefield,
                                                    Bangalore, India
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={{
                                                        pathname:
                                                            'tel:+918023377777',
                                                    }}
                                                    target="_blank"
                                                >
                                                    <i className="fi ti-mobile"></i>
                                                    080 23377777
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={{
                                                        pathname:
                                                            'mailto:info.essotto@gmail.com',
                                                    }}
                                                    target="_blank"
                                                >
                                                    <i className="fi flaticon-email"></i>
                                                    info.essotto@gmail.com
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget link-widget resource-widget">
                                    <div className="widget-title">
                                        <h3>Destination</h3>
                                    </div>
                                    <div className="news-wrap">
                                        <div className="news-img">
                                            <img src={ft1} alt="" />
                                        </div>
                                        <div className="news-text">
                                            <h3>Paris, France</h3>
                                            <span>11 Jun 2020 - 22 Jun 2020</span>
                                            <h2>$835</h2>
                                        </div>
                                    </div>
                                    <div className="news-wrap">
                                        <div className="news-img">
                                            <img src={ft2} alt="" />
                                        </div>
                                        <div className="news-text">
                                            <h3>San Francisco</h3>
                                            <span>11 Jun 2020 - 22 Jun 2020</span>
                                            <h2>$835</h2>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget link-widget">
                                    <div className="widget-title">
                                        <h3>Useful Links</h3>
                                    </div>
                                    <ul>
                                        <li>
                                            <Link
                                                onClick={ClickHandler}
                                                to="/about"
                                            >
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                onClick={ClickHandler}
                                                to="/contact"
                                            >
                                                Contact Us
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="widget newsletter-widget">
                                    <div className="widget-title">
                                        <h3>Newsletter</h3>
                                    </div>
                                    <form onSubmit={SubmitHandler}>
                                        <div className="input-1">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email Address *"
                                                required=""
                                            />
                                        </div>
                                        <div className="submit clearfix">
                                            <button type="submit">
                                                subscribe
                                                <i className="ti-angle-right"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-6 col-md-6 col-12">
                            <div className="term">
                                <ul>
                                    <li>
                                        <Link to="/privacy.pdf" target="_blank">
                                            Privacy Policy
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/terms.pdf" target="_blank">
                                            Terms & Condition
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <Link onClick={ClickHandler} to="/">
                                            Cookies
                                        </Link>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-6 col-md-6 col-12">
                            <p className="copyright">
                                COPYRIGHTS &copy; ESSOTTO PRIVATE LIMITED. ALL RIGHTS RESERVED
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;