import React from 'react';
import ContactForm from '../ContactFrom'
import './style.css'

const mapURL =
    'https://www.google.com/maps/place/Essotto+Recreation+Hub/@12.9767836,77.7243486,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae15386493fe8d:0xc36eac3f36cb737d!8m2!3d12.9767836!4d77.7265373!16s%2Fg%2F11t9pg43wh';
const phoneURL = 'tel:+918023377777';
const mailURL = 'mailto:info.essotto@gmail.com';

const Contactpage = () => {

    return (
        <div id="Contact" className="contact-area section-padding">
            <div className="container">
                <div className="wpo-contact-info">
                    <div className="row">
                        <div
                            className="col-lg-4 col-md-6 col-sm-12 col-12"
                            onClick={() => window.open(mapURL, '_blank')}
                        >
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon">
                                        <i className="fi flaticon-internet"></i>
                                    </div>
                                    <div className="info-text">
                                        <span>Hotel Address</span>
                                    </div>
                                </div>
                                <h2>
                                    Essotto Recreational Hub, Whitefield,
                                    Bangalore, India
                                </h2>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6 col-sm-12 col-12"
                            onClick={() => window.open(mailURL, '_blank')}
                        >
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon">
                                        <i className="fi flaticon-email"></i>
                                    </div>
                                    <div className="info-text">
                                        <span>Official Mail</span>
                                    </div>
                                </div>
                                <h2>info.essotto@gmail.com</h2>
                            </div>
                        </div>
                        <div
                            className="col-lg-4 col-md-6 col-sm-12 col-12"
                            onClick={() => window.open(phoneURL, '_blank')}
                        >
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon">
                                        <i className="fi flaticon-null-1"></i>
                                    </div>
                                    <div className="info-text">
                                        <span>Official Phone</span>
                                    </div>
                                </div>
                                <h2>080 23377777</h2>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="contact-content">
                    <h2>Send a Message</h2>
                    <div className="contact-form">
                        <ContactForm />
                    </div>
                </div> */}
                <div className="contact-map">
                    <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Essotto,%20Bangalore,%20Karnataka,%20India+(Essotto)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div>
            </div>
        </div>
    );
        
}

export default Contactpage;
