import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  reviews: [],
};

export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    loadReviews: (state, action) => {
      state.reviews =action.payload;
    },
    addReview: (state, action) => {
      const newReview = action.payload;
      state.reviews = [newReview, ...state.reviews];
    },
  },
});

// Action creators are generated for each case reducer function
export const {loadReviews, addReview} = reviewSlice.actions;

export default reviewSlice.reducer;
