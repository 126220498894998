import React from 'react'
import {Link} from 'react-router-dom'
// import ab1 from '../../images/about/about.png'
import './style.css'
const ab1 = 'https://source.unsplash.com/qRAMxGJcrEk';

const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return (
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={ab1} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                                <span>About Us</span>
                                <h2>
                                    All in one <br /> Recreational Hub{' '}
                                </h2>
                            </div>
                            <p>
                                Essotto is built on the principle of providing
                                amazing ambeinece to service to all our guests.
                                Our wide range of services make us a one stop
                                venue for all your recreational needs.
                            </p>
                            <br />
                            <p>
                                
                            </p>
                            <div className="btns">
                                <Link
                                    onClick={ClickHandler}
                                    to="/about"
                                    className="theme-btn-s2"
                                >
                                    More About Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;