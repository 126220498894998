import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import RoomSingleSection from '../../components/RoomSingleSection'
import { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import Globals from '../../constants/Globals';
import { useSelector, useDispatch } from 'react-redux';
import { addRestData } from '../../redux/features/restaurant/restaurantSlice';


const RoomSinglePage =({location}) => {

    let dispatch = useDispatch();

    let service = location && location.service ? location.service : null;

    const mode = 'dine-in';
    const [services, setServices] = useState([]);
    const [restdata, setRestData] = useState(null);

    const getCategoryItems = (data) => {
        let recommendedItems = [];
        let menuTable = {};
        let categoryItems = {};
        let categoryIDItems = {};
        let starredItems = [];
        let categoriesList = [];
        let categoryTable = {};
        let pos_item_menugrps = {};
        let menugrps = {};

        if (data && data.menu_items && data.menu_items.length > 0) {
            let menuItems = data.menu_items.sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt);
            });

            let tax_charges = data.taxes;
            // console.log('TAX --', tax_charges);
            menuItems.forEach((item) => {
                let subcat = item.sub_category || 'All';
                let subcat_id = item.sub_category_id || 'All';

                if (item.isRecommended) {
                    recommendedItems.push(item);
                }

                menuTable[item.item_id] = item;

                menuTable[item.item_id].item_tax_details = item.tax_group_id
                    ? tax_charges.filter(
                          (x) => x.tax_group_id === item.tax_group_id
                      )
                    : [];

                if (!categoryItems[item.category]) {
                    categoryItems[item.category] = {};
                }

                if (!categoryIDItems[item.category_id]) {
                    categoryIDItems[item.category_id] = {};
                }

                if (!categoryItems[item.category][subcat]) {
                    categoryItems[item.category][subcat] = [];
                }

                if (!categoryIDItems[item.category_id][subcat_id]) {
                    categoryIDItems[item.category_id][subcat_id] = [];
                }

                categoryItems[item.category][subcat].push(item);
                categoryIDItems[item.category_id][subcat_id].push(item);

                if (item.isStarred) {
                    starredItems.push(item);
                }
            });

            if (data.categoryList && data.categoryList.length > 0) {
                categoriesList = data.categoryList.sort((a, b) =>
                    a.orderNum && b.orderNum
                        ? a.orderNum - b.orderNum
                        : a.orderNum
                        ? -1
                        : 1
                );
                // console.log("CONTEXT getCategoriesList :::::::   ", categoriesList);
                for (let cat of categoriesList) {
                    categoryTable[cat.id] = {
                        ...cat,
                        itemCount: {
                            'dine-in': 0,
                            takeaway: 0,
                            delivery: 0,
                            null: 0,
                        },
                    };
                    categoryIDItems[cat.id] &&
                        cat.isAvailable &&
                        Object.entries(categoryIDItems[cat.id]).forEach(
                            ([subcat, itemsList]) => {
                                //(subcat === "All" || (categoryTable[subcat] && categoryTable[subcat].isAvailable)) &&
                                itemsList.forEach((val) => {
                                    if (val.isAvailable) {
                                        for (let menu_type of val.menu_type) {
                                            if (menu_type) {
                                                categoryTable[cat.id].itemCount[
                                                    menu_type
                                                ]++;
                                                if (
                                                    !pos_item_menugrps[
                                                        menu_type
                                                    ]
                                                ) {
                                                    pos_item_menugrps[
                                                        menu_type
                                                    ] = [];
                                                }

                                                let mentype = cat.menu_group;

                                                menugrps[mentype] = {
                                                    description:
                                                        cat.menu_group_description,
                                                    image: cat.menu_group_image,
                                                };

                                                if (
                                                    pos_item_menugrps[
                                                        menu_type
                                                    ].indexOf(mentype) === -1
                                                ) {
                                                    pos_item_menugrps[
                                                        menu_type
                                                    ].push(mentype);
                                                }
                                            } else {
                                                categoryTable[cat.id].itemCount[
                                                    'null'
                                                ]++;
                                            }
                                        }
                                    }
                                });
                            }
                        );
                }
            } else {
                categoriesList = [];
            }

            var returndata = {
                restdata: data,
                categoryItems,
                categoryIDItems,
                recommendedItems,
                categoriesList,
                categoryTable,
                menuTable,
                pos_item_menugrps,
                menugrps,
                starredItems,
            };
            return returndata;
        } else {
            var returndata = {
                restdata: data,
                categoryItems,
                categoryIDItems,
                recommendedItems,
                categoriesList,
                categoryTable,
                menuTable,
                pos_item_menugrps,
                menugrps,
                starredItems,
            };
            return returndata;
        }
    };

    const getRestServices = useCallback(async () => {
        try {
            const url =
                Globals.api_url + `/services/restaurant/${service? service.rest_id : null}`;
            console.log('THE URL --', url);
            const res = await axios({
                url,
                method: 'GET',
            });
            console.log('SERVICE COUNT ---', res.data);
            let serve = res.data.sort((a, b) => a.price - b.price);
            setServices(serve);
            return Promise.resolve(serve);
        } catch (error) {
            console.log('THE ERROR --', error);
            return Promise.reject(error);
        }
    }, []);

    const getRest = useCallback(async () => {
        try {
            const url = Globals.api_url + `/restaurant/${service? service.rest_id : null}`;
            console.log('THE URL --', url);
            const res = await axios(url);
            // console.log('Restdata ---', res.data)

            let retdata = getCategoryItems(res.data);
            // console.log(retdata)
            setRestData(retdata);
            return Promise.resolve(retdata);
            // await dispatch(addRestData(retdata))
        } catch (error) {
            console.log('THE ERROR --', error);
            return Promise.reject(error);
        }
    }, []);

    //TODO : Uncomment later
    // useEffect(() => {
    //     (async () => {
    //         let data = await getRest();
    //         if (data.restdata.is_room_service) {
    //             let services = await getRestServices();
    //             data.services = services;
    //         }
    //         await dispatch(addRestData(data));
    //     })();
    //     // getRest();
    // }, []);
    
    // if(restdata) {
        return (
            <Fragment>
                <Navbar />
                {/* <PageTitle
                    pageTitle={restdata.restdata.name}
                    pagesub={restdata.restdata.name}
                /> */}
                <PageTitle
                    pageTitle={service.name}
                    pagesub={service.name}
                    image={'https://source.unsplash.com/qRAMxGJcrEk'}
                />
                {/* <RoomSingleSection restdata={restdata} /> */}
                <RoomSingleSection restdata={service} />
                <Footer />
                <Scrollbar />
            </Fragment>
        );
    // } else {
    //     return( <div>Loading...</div>);
    // }
};
export default RoomSinglePage;
