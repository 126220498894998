import React from 'react'
import {Link}  from 'react-router-dom'
import './style.css'

const mapURL =
    'https://www.google.com/maps/place/Essotto+Recreation+Hub/@12.9767836,77.7243486,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae15386493fe8d:0xc36eac3f36cb737d!8m2!3d12.9767836!4d77.7265373!16s%2Fg%2F11t9pg43wh';
    const mailURL = 'mailto:info.essotto@gmail.com';

const HeaderTopbar = () => {
    return (
        <div className="topbar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-md-6 col-sm-7 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li>
                                    <Link
                                        to={{ pathname: mapURL }}
                                        target="_blank"
                                    >
                                        <i className="fi ti-location-pin"></i>
                                        Essotto Recreational Hub, Whitefield,
                                        Bangalore, India
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={{ pathname: mailURL }}
                                        target="_blank"
                                    >
                                        <i className="fi flaticon-email"></i>
                                        info.essotto@gmail.com
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-md-6 col-sm-5 col-12">
                        <div className="contact-info">
                            <ul>
                                <li>
                                    <Link
                                        to={{
                                            pathname:
                                                'https://facebook.com/Essotto.Official',
                                        }}
                                        target="_blank"
                                    >
                                        <i className="ti-facebook"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={{
                                            pathname:
                                                'https://twitter.com/Essotto_oficial',
                                        }}
                                        target="_blank"
                                    >
                                        <i className="ti-twitter-alt"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={{
                                            pathname:
                                                'https://www.instagram.com/essotto.official/',
                                        }}
                                        target="_blank"
                                    >
                                        <i className="ti-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to={{
                                            pathname: 'https://g.co/kgs/HBaUz8',
                                        }}
                                        target="_blank"
                                    >
                                        <i className="ti-google"></i>
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link className="theme-btn-s2" to="#">
                                        Book Now{' '}
                                    </Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderTopbar;