import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import {toast} from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { InputAdornment } from '@material-ui/core';
import Checkbox from "@material-ui/core/Checkbox";
import {Link, withRouter} from "react-router-dom";
import { MuiOtpInput } from 'mui-one-time-password-input';

import './style.scss';

const LoginPage = (props) => {
    const [value, setValue] = useState({
        email: 'user@gmail.com',
        password: '123456',
        remember: false,
        phone: "9030341242"
    });

    const [step, setStep] = useState(1);
    const [otp, setOtp] = useState("    ");
    const [otpSent, setOtpsent] = useState(false);

    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
        validator.showMessages();
    };

    const rememberHandler = () => {
        setValue({...value, remember: !value.remember});
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const submitForm = (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            setValue({
                email: '',
                password: '',
                remember: false
            });
            validator.hideMessages();

            const userRegex = /^user+.*/gm;
            const email = value.email;

            if (email.match(userRegex)) {
                toast.success('You successfully Login on Essotto !');
                props.history.push('/home');
            }
        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };
    return (
        <Grid className="loginWrapper">
            <Grid className="loginForm">
                <h2>Sign In</h2>
                <p>Sign in to your account</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Mobile Number"
                                value={value.phone}
                                variant="outlined"
                                name="phone"
                                label="Phone"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            +91
                                        </InputAdornment>
                                    ),
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message(
                                'phone',
                                value.phone,
                                'required'
                            )}
                        </Grid>
                        {otpSent ? (
                            <Grid item xs={12}>
                                <MuiOtpInput
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                                {/* <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Password"
                                value={value.password}
                                variant="outlined"
                                name="password"
                                type="password"
                                label="Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message(
                                'password',
                                value.password,
                                'required'
                            )} */}
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            {/* <Grid className="formAction">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={value.remember}
                                            onChange={rememberHandler}
                                        />
                                    }
                                    label="Remember Me"
                                />
                                <Link to="/forgot-password">
                                    Forgot Password?
                                </Link>
                            </Grid> */}
                            {otpSent ? (
                                <Grid className="formFooter">
                                    <Button
                                        fullWidth
                                        className="cBtnTheme"
                                        type="resend"
                                    >
                                        Resend OTP
                                    </Button>
                                    <Button
                                        fullWidth
                                        className="cBtnTheme"
                                        type="submit"
                                    >
                                        Submit OTP
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid className="formFooter">
                                    <Button
                                        fullWidth
                                        className="cBtnTheme"
                                        type="submit"
                                    >
                                        Send OTP
                                    </Button>
                                </Grid>
                            )}
                            <Grid className="loginWithSocial">
                                <Button className="google">
                                    <i className="fa fa-google"></i>
                                </Button>
                                {/* <Button className="twitter">
                                    <i className="fa fa-twitter"></i>
                                </Button>
                                <Button className="linkedin">
                                    <i className="fa fa-linkedin"></i>
                                </Button> */}
                            </Grid>
                            {/* <p className="noteHelp">
                                Don't have an account?{' '}
                                <Link to="/signup">Create free account</Link>
                            </p> */}
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Grid>
    );
};

export default withRouter(LoginPage);