import { configureStore } from '@reduxjs/toolkit'
// import {reviewAPI} from './features/review/reviewService';
import { setupListeners } from '@reduxjs/toolkit/query'
import reviewSlice from './features/review/reviewSlice'
import restaurantSlice from './features/restaurant/restaurantSlice'
import cartSlice from './features/cart/cartSlice';

export const store = configureStore({
	reducer: {
		review: reviewSlice,
		restaurant: restaurantSlice,
		cart: cartSlice,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		}),
})

setupListeners(store.dispatch);
