import redbox from '../assets/images/redbox-11.png';
import yellowbox from '../assets/images/yellobox-21.png';
import bluebox from '../assets/images/bluebox-12.png';
import pinkbox from '../assets/images/pinkbox-23.png';
import greenbox from '../assets/images/greenbox-22.png';
import purplebox from '../assets/images/purplebox-31.png';
import purplebox2 from '../assets/images/purplebox-41.png';
import brownbox from '../assets/images/brownbox-42.png';
import greybox from '../assets/images/greybox-43.png';

import cafe1 from '../assets/images/services/cafe1.jpeg';
import skycafe from '../assets/images/services/skycafe.jpeg'
import games from '../assets/images/services/games.jpeg';
import veg_rest from '../assets/images/services/veg-restaurant.jpg';
import none_veg_rest from '../assets/images/services/none-veg-restaurant.jpg';
import gym from '../assets/images/services/gym.jpg';
import swimming_pool from '../assets/images/services/swimming-pool.jpg';
import clinic from '../assets/images/services/clinic.jpg';
import yoga from '../assets/images/services/yoga.jpg';
import karaoke_room from '../assets/images/services/karaoke-room.jpg';
import home_theater from '../assets/images/services/home-theater.jpg';
import dance_studio from '../assets/images/services/dance-studio.jpg';
import rain_dance from '../assets/images/services/rain-dance.jpg';
import mini_bowling from '../assets/images/services/mini-bowling.jpg';
import snooker from "../assets/images/services/snooker.jpg";
import cricket from "../assets/images/services/cricket.jpg";
import tennis from "../assets/images/services/tennis.jpg";
import carrom_room from "../assets/images/services/carrom-room.jpg";
import chess from "../assets/images/services/chess.jpg";
import conference_rooms from "../assets/images/services/conference-rooms.jpg";
import work_stations from "../assets/images/services/work_stations.jpg";
import library from "../assets/images/services/library.jpg";
import laundry_service from "../assets/images/services/laundry_service.jpg";
import sauna from "../assets/images/services/sauna.jpg";

const url1 = 'https://source.unsplash.com/'
// + dest.image + 
const url2 = '/442x600';


// module.exports = {
//     services: services,
// };

export default [
    {
        name: 'Food & Drink',
        icon: 'fast-food-sharp',
        image: '-YHSwy6uqvk',
        bg_image: redbox,
        width: 0.6,
        services: [
            {
                id: 1,
                name: 'Veg Restaurant',
                image_2: url1 + 'poI7DelFiVA' + url2,
                image_bg: url1 + 'poI7DelFiVA',
                image: veg_rest,
                rest_id: 'L5E09A',
                description_long:
                    'Our foodcourts and cafes are spread across the floors to give customers easy access to food & drink whenever required.\nWe serve delicious food that can satiate all food cravings.Our dishes include the intricacy of locally sourced ingredients handled by trained chefs to bring the best of the palette to your plate.',
            },
            {
                id: 2,
                name: 'Non-Veg Restaurant',
                image_2: url1 + 'oCsaxvGCehM' + url2,
                image_bg: url1 + 'oCsaxvGCehM',
                image: none_veg_rest,
                rest_id: 'L5E09C',
                description_long:
                    'Our foodcourts and cafes are spread across the floors to give customers easy access to food & drink whenever required.\nWe serve delicious food that can satiate all food cravings.Our dishes include the intricacy of locally sourced ingredients handled by trained chefs to bring the best of the palette to your plate.',
            },
            {
                id: 3,
                name: 'Esso Cafe',
                image_bg: url1 + 'zUNs99PGDg0',
                image: cafe1,
                rest_id: 'L5E09A',
                description_long:
                    'Esso Cafe offers a unique coffee house environment unlike any other. We are not only a place to drop in and get your morning cup of coffee (although you are more than welcome to do that), we are a place where you can sit down and enjoy that tailor-made cup of coffee. If you need to work, we have a seating area created specifically for you. If you need to rest, we have a recreation pods area that is perfect for your weary mind and body.',
            },
            // {
            //     id: 4,
            //     name: 'Sky Cafe',
            //     image_bg: url1 + 'ZZxmc66SjfM',
            //     image: skycafe,
            //     rest_id: 'L5E09B',
            //     description_long:
            //         'With an amazing view of the city, the Sky Cafe offers a wonderful seating for an evening.\nIf you need to work, we have a seating area created specifically for you. If you need to rest, we have a recreation pods area that is perfect for your weary mind and body.',
            // },
        ],
    },
    {
        name: 'Health & Fitness',
        icon: 'fitness-sharp',
        image: '-hqq3di9few',
        bg_image: bluebox,
        width: 0.4,
        services: [
            {
                id: 1,
                name: 'Gym',
                image_2: url1 + '20jX9b35r_M' + url2,
                image_bg: url1 + '20jX9b35r_M',
                image: gym,
                rest_id: 'L5E09E',
                description_long:
                    'Our 22,000 square foot space allows us to carry everything you would look for in a gym! We have filled the gym with top of the line machines, weights and workout accessories to help all of our members reach their personal goals.\nThe stretching area includes mats, foam rollers, and machines to help you stretch as this is very important for your post-workout recovery.\nIn addition, Essotto has fantastic amenities such as steam rooms with healing powers of eucalyptus, dry saunas and showers... all equipped with body wash, shampoo, mouthwash, and soap! Our spacious locker rooms with electronic lock systems will definitely make coming to the gym before or after school/work so much easier so what are you waiting for?!',
            },
            {
                id: 2,
                name: 'Swimming Pool',
                image_2: url1 + '08AJKJf75kw' + url2,
                image_bg: url1 + '08AJKJf75kw',
                image: swimming_pool,
                rest_id: 'L5E09E',
            },
            // {
            //     id: 3,
            //     name: 'Mini Clinic',
            //     image_2: url1 + 'e7MJLM5VGjY' + url2,
            //     image_bg: url1 + 'e7MJLM5VGjY',
            //     image: clinic,
            //     rest_id: 'L5E09E',
            // },
            {
                id: 4,
                name: 'Yoga',
                image_2: url1 + 'NTyBbu66_SI' + url2,
                image_bg: url1 + 'NTyBbu66_SI',
                image: yoga,
                rest_id: 'L5E09E',
            },
        ],
    },
    {
        name: 'Entertainment',
        icon: 'game-controller-sharp',
        image: 'qc-kiqPzoy8',
        bg_image: yellowbox,
        width: 0.29,
        services: [
            {
                id: 1,
                name: 'Karaoke Rooms',
                image_2: url1 + 'uvEcXRoon3c' + url2,
                image_bg: url1 + 'uvEcXRoon3c',
                image: karaoke_room,
                rest_id: 'L5E09E',
            },
            // {
            // 	id: 2,
            // 	name: 'Massage Chairs',
            // 	image: 'dsy_ILnH69A',
            // 	rest_id: 'L5E09E',
            // },
            // {
            // 	id: 3,
            // 	name: 'Nail Art',
            // 	image: 'NTyBbu66_SI',
            // },
            {
                id: 4,
                name: 'Home Theater',
                image_2: url1 + 'qc-kiqPzoy8' + url2,
                image_bg: url1 + 'qc-kiqPzoy8',
                image: home_theater,
                rest_id: 'L5E09E',
            },
            {
                id: 5,
                name: 'Dance Studio',
                image_2: url1 + '3ckWUnaCxzc' + url2,
                image_bg: url1 + '3ckWUnaCxzc',
                image: dance_studio,
                rest_id: 'L5E09E',
            },
            {
                id: 6,
                name: 'Rain Disco',
                image_2: url1 + '249DzAuJTqQ' + url2,
                image_bg: url1 + '249DzAuJTqQ',
                image: rain_dance,
                rest_id: 'L5E09E',
            },
        ],
    },
    {
        name: 'Games',
        icon: 'game-controller-sharp',
        image: '6UeBe2HqziA',
        bg_image: greenbox,
        width: 0.29,
        services: [
            // {
            //     id: 1,
            //     name: 'Mini Bowling',
            //     image_2: url1 + 'lwTSdfXfskE' + url2,
            //     image_bg: url1 + 'lwTSdfXfskE',
            //     image: mini_bowling,
            //     rest_id: 'L5E09E',
            // },
            {
                id: 2,
                name: 'Snooker',
                image_2: url1 + '6UeBe2HqziA' + url2,
                image_bg: url1 + '6UeBe2HqziA',
                image: snooker,
                rest_id: 'L5E09E',
            },
            // {
            //     id: 3,
            //     name: 'E-Arena',
            //     image: games,
            //     image_bg: url1 + '6UeBe2HqziA',
            //     rest_id: 'L5E09E',
            // },
            // {
            //     id: 4,
            //     name: 'Cricket',
            //     image_2: url1 + 'dE3exzmYlKc' + url2,
            //     image_bg: url1 + 'dE3exzmYlKc',
            //     image: cricket,
            //     rest_id: 'L5E09E',
            // },
            {
                id: 5,
                name: 'Tennis',
                image_2: url1 + 'eLZwsPO8cCQ' + url2,
                image_bg: url1 + 'eLZwsPO8cCQ',
                image: tennis,
                rest_id: 'L5E09E',
            },
            {
                id: 6,
                name: 'Carrom Room',
                image_2: url1 + 'frUBD54Lt08' + url2,
                image_bg: url1 + 'frUBD54Lt08',
                image: carrom_room,
                rest_id: 'L5E09E',
            },
            {
                id: 6,
                name: 'Chess',
                image_2: url1 + 'SlntP-SLi0Q' + url2,
                image_bg: url1 + 'SlntP-SLi0Q',
                image: chess,
                rest_id: 'L5E09E',
            },
        ],
    },
    {
        name: 'Productivity',
        icon: 'desktop-sharp',
        image: 'NrR6ByFMBas',
        bg_image: pinkbox,
        width: 0.29,
        services: [
            {
                id: 1,
                name: 'Conference Rooms',
                image_2: url1 + 'ZPod9V7zB3A' + url2,
                image_bg: url1 + 'ZPod9V7zB3A',
                image: conference_rooms,
                rest_id: 'L5E09E',
            },
            {
                id: 2,
                name: 'Work Stations',
                image_2: url1 + 'NrR6ByFMBas' + url2,
                image_bg: url1 + 'NrR6ByFMBas',
                image: work_stations,
                rest_id: 'L5E09E',
            },
            {
                id: 3,
                name: 'Library',
                image_2: url1 + '1emWndlDHs0' + url2,
                image_bg: url1 + '1emWndlDHs0',
                image: library,
                rest_id: 'L5E09E',
            },
        ],
    },
    {
        name: 'Beauty & Wellness',
        icon: 'business-sharp',
        image: 'NHLS5hOSH0c',
        bg_image: purplebox,
        width: 0.91,
        services: [
            {
                id: 1,
                name: 'Laundry Service',
                image_2: url1 + 'NPPNHZK1U0s' + url2,
                image_bg: url1 + 'NPPNHZK1U0s',
                image: laundry_service,
                rest_id: 'L5E09E',
            },
            {
                id: 2,
                name: 'Sauna',
                image_2: url1 + 'NHLS5hOSH0c' + url2,
                image_bg: url1 + 'NHLS5hOSH0c',
                image: sauna,
                rest_id: 'L5E09F',
            },
            {
                id: 3,
                name: 'Steam',
                image: url1 + 'DoIjsjFk_F8' + url2,
                image_bg: url1 + 'DoIjsjFk_F8',
                rest_id: 'L5E09F',
            },
            // {
            //     id: 4,
            //     name: 'Fish Spa',
            //     image: url1 + 'lqxaJL8Orfc' + url2,
            //     image_bg: url1 + 'lqxaJL8Orfc',
            //     rest_id: 'L5E09F',
            // },
            // {
            //     id: 5,
            //     name: 'Water Spa',
            //     image: url1 + 'rlNibgIqi4o' + url2,
            //     image_bg: url1 + 'rlNibgIqi4o',
            //     rest_id: 'L5E09F',
            // },
            {
                id: 6,
                name: 'Regular Spa',
                image: url1 + 'W7AyAs7azHc' + url2,
                image_bg: url1 + 'W7AyAs7azHc',
                rest_id: 'L5E09F',
            },
            {
                id: 6,
                name: 'Salon',
                image: url1 + '_C-S7LqxHPw' + url2,
                image_bg: url1 + '_C-S7LqxHPw',
                rest_id: 'L5E09F',
            },
            {
                id: 7,
                name: 'Nail Art',
                image: url1 + 't1uTqt6qhWI' + url2,
                image_bg: url1 + 't1uTqt6qhWI',
                rest_id: 'L5E09F',
            },
        ],
    },
    {
        name: 'Stays',
        icon: 'bed-sharp',
        image: 'OtXADkUh3-I',
        bg_image: purplebox2,
        width: 0.43,
        services: [
            {
                id: 1,
                name: 'Deluxe Room',
                image: url1 + 'OtXADkUh3-I' + url2,
                image_bg: url1 + 'OtXADkUh3-I',
                rest_id: 'L5E09G',
                service_id: 'ROOM01',
            },
            {
                id: 2,
                name: 'Standard Room',
                image: url1 + 'kyt0PkBSCNQ' + url2,
                image_bg: url1 + 'kyt0PkBSCNQ',
                rest_id: 'L5E09G',
                service_id: ' ',
            },
            {
                id: 3,
                name: 'Premium Room',
                image: url1 + 'gREquCUXQLI' + url2,
                image_bg: url1 + 'gREquCUXQLI',
                rest_id: 'L5E09G',
                service_id: 'ROOM01',
            },
            {
                id: 4,
                name: 'Suite Room',
                image: url1 + 'r_y2VBvEOIE' + url2,
                image_bg: url1 + 'r_y2VBvEOIE',
                rest_id: 'L5E09G',
                service_id: 'ROOM01',
            },
        ],
    },
    {
        name: 'Relaxation',
        icon: 'bed-sharp',
        image: 'v6nyYt7lQ-Y',
        bg_image: brownbox,
        width: 0.22,
        services: [
            {
                id: 1,
                name: 'Massage Chairs',
                image: url1 + '-Yp5Uruu7bA' + url2,
                image_bg: url1 + '-Yp5Uruu7bA',
                rest_id: 'L5E09E',
            },
            {
                id: 2,
                name: 'Recreation Pods',
                image: url1 + 'v6nyYt7lQ-Y' + url2,
                image_bg: url1 + 'v6nyYt7lQ-Y',
                rest_id: 'L5E09E',
            },
            {
                id: 3,
                name: 'VIP Sleeping Pods',
                image: url1 + 'RDIa_qFpWHc' + url2,
                image_bg: url1 + 'RDIa_qFpWHc',
                rest_id: 'L5E09E',
            },
        ],
    },
    {
        name: 'Shop',
        icon: 'bed-sharp',
        image: 'Rl6Xep37xS0',
        bg_image: greybox,
        width: 0.22,
        services: [
            {
                id: 1,
                name: 'Marketplace',
                image: url1 + 'Rl6Xep37xS0' + url2,
                image_bg: url1 + 'Rl6Xep37xS0',
                rest_id: 'L5E09A',
            },
            {
                id: 2,
                name: 'Marketplace',
                image: url1 + 'Rl6Xep37xS0' + url2,
                image_bg: url1 + 'Rl6Xep37xS0',
                rest_id: 'L5E09A',
            },
            {
                id: 3,
                name: 'Marketplace',
                image: url1 + 'Rl6Xep37xS0' + url2,
                image_bg: url1 + 'Rl6Xep37xS0',
                rest_id: 'L5E09A',
            },
        ],
    },
];
