import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	restdata: null,
	categoryItems : {},
	categoryIDItems : {},
	recommendedItems  : [],
	categoriesList : [],
	categoryTable : {},
	menuTable : {},
	pos_item_menugrps : {},
	menugrps : {},
	starredItems : [],
}

export const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    addRestData: (state, action) => {

        state.restdata = action.payload.restdata;
        state.categoryIDItems = action.payload.categoryIDItems;
        state.categoriesList = action.payload.categoriesList;
        state.menuTable = action.payload.menuTable;
        state.pos_item_menugrps = action.payload.pos_item_menugrps;
        state.menugrps = action.payload.menugrps;
        state.starredItems = action.payload.starredItems;
        state.recommendedItems = action.payload.recommendedItems;
        state.categoryTable = action.payload.categoryTable;
        state.services = action.payload.services || null;

        // state = {...state, ...action.payload};

        // console.log({payload : action.payload});
      

        // let {
        //         restdata,
		// 		categoryItems,
		// 		categoryIDItems,
		// 		recommendedItems,
		// 		categoriesList,
		// 		categoryTable,
		// 		menuTable,
		// 		pos_item_menugrps,
		// 		menugrps,
		// 		starredItems,
		// 	} = action.payload;

        //     state = {
        //         ...state,
        //         restdata : {...restdata},
        //         categoriesList : [...categoriesList],
        //         categoryItems : {...categoryItems},
        //         categoryIDItems : {...categoryIDItems},
        //         recommendedItems : [...recommendedItems],
        //         categoryTable : {...categoryTable},
        //         menuTable : {...menuTable},
        //         pos_item_menugrps : {...pos_item_menugrps},
        //         menugrps : {...menugrps},
        //         starredItems : [...starredItems],
        //     }

    },
  },
});

// Action creators are generated for each case reducer function
export const {addRestData} = restaurantSlice.actions;

export default restaurantSlice.reducer;
