import React from 'react';
import { Link } from 'react-router-dom';
import Pricing from '../Rcomponents/descontent/pricing';
import Review from '../Rcomponents/descontent/review';
import AddReview from '../Rcomponents/descontent/addReview';
import OtherRoom from '../Rcomponents/descontent/otherRoom';
// import rmd1 from '../../../images/room/img-7.jpg'
// import rmd2 from '../../../images/room/img-8.jpg'
import Destination2 from '../../Destination2';

const rmd1 = 'https://source.unsplash.com/YMOHw3F1Hdk/529x406';
const rmd2 = 'https://source.unsplash.com/DGa0LQ0yDPc/529x406';

const Description = ({ restdata, menu_groups }) => {
    return (
        <div className="Room-details-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="room-description">
                            {restdata.description_long ? (
                                <div className="room-title">
                                    <h2>Description</h2>
                                </div>
                            ) : null}
                            {restdata.description_long &&
                                restdata.description_long
                                    .split('\\n')
                                    .map((eachtext) => (
                                        <>
                                            <p className="p-wrap">{eachtext}</p>
                                            <br />
                                        </>
                                    ))}
                        </div>
                        {/* {restdata.is_room_service ? ( */}
                        <div className="room-details-service">
                            <div className="row">
                                <div className="room-title">
                                    <h2>Other Services at Essotto</h2>
                                </div>{' '}
                                <div className="room-details-item">
                                    <div className="row">
                                        <div className="col-md-5 col-sm-12 col-12">
                                            <div className="room-d-text">
                                                <div className="room-title">
                                                    <h2>Amenities</h2>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <Link to="/details">
                                                            Guest Wifi
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/details">
                                                            Air Conditioner
                                                            Facilities
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/details">
                                                            Hangout Cafes
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-sm-12 col-12">
                                            <div className="room-d-img">
                                                <img src={rmd1} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-7 col-sm-12 col-12">
                                            <div className="room-d-img">
                                                <img src={rmd2} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12 col-12">
                                            <div className="room-d-text2">
                                                <div className="room-title">
                                                    <h2>Room Services</h2>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <Link to="/details">
                                                            Daily Cleaning
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/details">
                                                            Special Swimming
                                                            Pool
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/details">
                                                            Free Parking
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/details">
                                                            Free Wifi
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/details">
                                                            2 Elevator Available
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/details">
                                                            Room Side Belcony
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ) : (
                            <Destination2 menu_groups={menu_groups} />
                        )} */}

                        {/* <Pricing/> */}

                        {/* TODO : uncomment here <Review /> */}
                        {/* <AddReview /> */}
                        {/* <OtherRoom/> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Description;
