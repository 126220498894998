import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2'
import Hero from '../../components/hero'
import SearchSection from '../../components/SearchSection'
import About2 from '../../components/about2'
import Features from '../../components/Features'
import Testimonial from '../../components/Testimonial'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
// import hero1 from '../../images/slider/slide-4.jpg'
import hero1 from '../../assets/images/home/home1.jpeg';
import hero2 from '../../assets/images/home/home2.jpeg'
import hero3 from '../../assets/images/home/home3.jpeg';
// import hero3 from '../../images/slider/slide-6.jpg'

// const hero1 = 'https://source.unsplash.com/8CDuHXff3zo';
// const hero2 = 'https://source.unsplash.com/9DAlSwg_bNY';
// const hero3 = 'https://source.unsplash.com/_6leEoWt7aI';

const HomePage2 =() => {
    return (
        <Fragment>
            <Navbar2 />
            <Hero
                heroClass={'hero-style-2'}
                heroImg1={hero1}
                heroImg2={hero2}
                heroImg3={hero3}
            />
            {/* <SearchSection/> */}
            <About2 />
            {/* <Destination2/> */}

            <Features />
            {/* <RoomSection /> */}
            {/* <Testimonial /> */}
            {/* <BlogSection /> */}
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};
export default HomePage2;