import {createSlice} from '@reduxjs/toolkit';

const initialState = {
	unconfirmedOrders : [],
    unconfirmedSubTotal : 0,
    unconfirmedTaxTotal : 0,
    unconfirmedTotal : 0,
    unconfirmedCount : 0,
}

const updateOrderWithValues = (order) => {
    
    let taxInfo = {};
    let taxTotal = 0;

    // order.taxTotal = 0;
    let {tax_details} = order;

    let itemTaxes = tax_details.filter(
        (x) => x.tax_on_tax === null
    );
    let dependentTaxes = tax_details.filter(
        (x) => x.tax_on_tax !== null
    );

    for (const index in itemTaxes) {
        let tax = itemTaxes[index];
        let tax_amount = Math.round(order.orderTotal * tax.tax_amount) / 100;
        if (!taxInfo[tax.id]) {
            taxInfo[tax.id] = {
                name: tax.name,
                description: tax.description,
                tax_amount: tax.tax_amount,
                actual_tax: 0.0,
            };
        }
        taxInfo[tax.id].actual_tax += tax_amount;
        taxTotal += tax_amount;
    }

    for (const index in dependentTaxes) {
        let tax = dependentTaxes[index];
        let tax_amount = 0.0;
        if (taxInfo[tax.tax_on_tax]) {
            tax_amount = Math.round(taxInfo[tax.tax_on_tax].actual_tax * tax.tax_amount) / 100;
        }

        if (!taxInfo[tax.id]) {
            taxInfo[tax.id] = {
                name: tax.name,
                description: tax.description,
                tax_amount: tax.tax_amount,
                actual_tax: 0.0,
            };
        }
        // taxDetails[tax.id].actual_tax += tax_amount;
        taxInfo[tax.id].actual_tax += tax_amount;
        taxTotal += tax_amount;           
    }

    order.taxInfo = taxInfo;
    order.taxTotal = taxTotal;

    return order;
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateOrder : (state, action) => {
        // console.log('updated ---', id, quantity);
        let {id, quantity} = action.payload;

        if (quantity > 0) {
            let currOrder = state.unconfirmedOrders
                .filter((x) => x.id === id)
                .pop();

            let otherorders = state.unconfirmedOrders.filter(x => x.id !== id);

            let diffquantity = quantity - currOrder.quantity;
            let itemOrders = state.unconfirmedOrders.filter(
                (x) => x.item_id === currOrder.item_id
            );
            let itemOrdersQuantity = 0;

            for (let order of itemOrders) {
                itemOrdersQuantity += order.quantity;
            }

            let allowedQuantity = currOrder.itemdata.item_limit
                ? currOrder.itemdata.item_limit - itemOrdersQuantity
                : 9999;

            if (
                !currOrder.itemdata.item_limit ||
                diffquantity <= allowedQuantity
            ) {
                currOrder.quantity = quantity;
                currOrder.orderTotal = currOrder.item_total * currOrder.quantity;

                let neworders = [
									...otherorders,
									updateOrderWithValues(currOrder),
								].sort((a, b) => a.id - b.id)
                
                state.unconfirmedOrders = neworders;
                // state.unconfirmedSubTotal = neworders.reduce((a,b) => a + b.orderTotal, 0);
                // state.unconfirmedTaxTotal = neworders.reduce((a, b) => a + b.taxTotal, 0);
                // state.unconfirmedTotal = neworders.reduce((a, b) => a + b.taxTotal + b.orderTotal,0);
                // state.unconfirmedCount = neworders.reduce((a, b) => a + b.quantity,0)
                // this.updateUnconfirmedCount();
            } else {
                //Do nothing
            }
            //cogoToast.success("Updated cart", { hideAfter: 2 });
        } else {
            let otherorders = state.unconfirmedOrders.filter(x => x.id !== id);
            state.unconfirmedOrders = otherorders.sort((a, b) => a.id - b.id);
            // state.unconfirmedSubTotal = otherorders.reduce(
			// 				(a, b) => a + b.orderTotal,
			// 				0
			// 			)
			// state.unconfirmedTaxTotal = otherorders.reduce(
			// 	(a, b) => a + b.taxTotal,
			// 	0
			// )
			// state.unconfirmedTotal = otherorders.reduce(
			// 	(a, b) => a + b.taxTotal + b.orderTotal,
			// 	0
			// )
            // state.unconfirmedCount = otherorders.reduce(
			// 				(a, b) => a + b.quantity,
			// 				0
			// 			)
            // this.removeOrder(id);
        }
    },
    removeOrder : (state, action) => {
        let {id} = action.payload;
        let neworders = [...state.unconfirmedOrders]
					.filter((eachorder) => id && eachorder.id !== id)
					.sort((a, b) => a.id - b.id)

        state.unconfirmedOrders = neworders;
        // state.unconfirmedSubTotal = neworders.reduce(
		// 			(a, b) => a + b.orderTotal,
		// 			0
		// 		)
		// state.unconfirmedTaxTotal = neworders.reduce(
		// 			(a, b) => a + b.taxTotal,
		// 			0
		// 		)
		// state.unconfirmedTotal = neworders.reduce(
		// 			(a, b) => a + b.taxTotal + b.orderTotal,
		// 			0
		// )
        // state.unconfirmedCount = neworders.reduce((a, b) => a + b.quantity, 0)
        // window.removeCookie('unconfirmedOrders');
        // window.setCookie('unconfirmedOrders', this.unconfirmedOrders, 360);
        // this.updateUnconfirmedCount();
        // if(this.unconfirmedOrders.length === 0) {
        //     this.setState({selectedDeliverySlot : null});
        //     window.removeCookie('selectedDeliverySlot');
        // }
    },
    addOrders : (state, action) => {
        let order = action.payload;
        let itemOrders = [...state.unconfirmedOrders].filter(
            (x) => x.item_id === order.item_id
        );
        let itemOrdersQuantity = 0;
        for (let eorder of itemOrders) {
            itemOrdersQuantity += eorder.quantity;
        }

        let allowedQuantity = order.itemdata.item_limit
            ? order.itemdata.item_limit - itemOrdersQuantity
            : 9999;

        if (!order.itemdata.item_limit || order.quantity <= allowedQuantity) {
            order.subtitle = '';
            // let options_ = order.options_json ? JSON.parse(order.options_json) : null;
            if (order.options.length > 0) {
                order.options.forEach((option) => {
                    order.subtitle += option.choicename
                        ? option.choicename + ','
                        : option.name + ',';
                });
                order.options_json = JSON.stringify(order.options);
                
                order.subtitle = order.subtitle
                    .trim()
                    .replace(/.$/, '.')
                    .substr(0, 80);
            }
            order.orderTotal = order.item_total * order.quantity;
            let neworders = [...state.unconfirmedOrders, updateOrderWithValues(order)];
            
            state.unconfirmedOrders = neworders;
            // state.unconfirmedSubTotal = neworders.reduce(
			// 				(a, b) => a + b.orderTotal,
			// 				0
			// 			)
			// state.unconfirmedTaxTotal = neworders.reduce(
			// 				(a, b) => a + b.taxTotal,
			// 				0
			// 			)
			// state.unconfirmedTotal = neworders.reduce(
			// 				(a, b) => a + b.taxTotal + b.orderTotal,
			// 				0
			// )
            // state.unconfirmedCount = neworders.reduce(
			// 				(a, b) => a + b.quantity,
			// 				0
			// 			)
           
            // this.updateUnconfirmedCount();
        } else {
            //Do nothing
        }
    },
    updateOrderInstructions : (state, action) => {
        let { id, instructions } = action.payload;
        let currOrder = state.unconfirmedOrders.filter((x) => x.id === id).pop();
        let otherorders = state.unconfirmedOrders.filter((x) => x.id !== id)
        currOrder.instructions = instructions;

        state.unconfirmedOrders = [...otherorders, currOrder].sort((a, b) => a.id - b.id)
    }
  },
});

// Action creators are generated for each case reducer function
export const { addOrders, updateOrder, removeOrder, updateOrderInstructions } =
	cartSlice.actions

export default cartSlice.reducer;
