import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import fimg1 from '../../images/featured/1.jpg'
// import fimg2 from '../../images/featured/2.jpg'
import fimg3 from '../../images/featured/3.jpg';
import fimg4 from '../../images/featured/4.jpg';
import fimg5 from '../../images/featured/5.jpg';
import fimg6 from '../../images/featured/6.jpg';

import './style.css';
import services from '../../constants/services';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Destination3 from '../Destination3';

// const fimg1 = 'https://source.unsplash.com/-YHSwy6uqvk/640x350';
// const fimg2 = 'https://source.unsplash.com/-hqq3di9few/640x350';

const Features = (props) => {
    // const features = [
    //     {
    //       Fimg:fimg1,
    //       heading:"Delicious Food",
    //       link:"/details",
    //     },
    //     {
    //       Fimg:fimg2,
    //       heading:"Swimming Pool",
    //       link:"/details",
    //     },
    //     {
    //       Fimg:fimg3,
    //       heading:"Spa salon",
    //       link:"/details",
    //     },
    //     {
    //       Fimg:fimg4,
    //       heading:"Game Room",
    //       link:"/details",
    //     },
    //     {
    //       Fimg:fimg5,
    //       heading:"Airport Taxi",
    //       link:"/details",
    //     },
    //     {
    //       Fimg:fimg6,
    //       heading:"Breakfast",
    //       link:"/details",
    //     },
    // ]

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="featured-area featured-sub">
            <div className="container-fluid">
                <div className="col-12">
                    <div className="wpo-section-title">
                        <span>Our Recreations</span>
                        <h2>Explore your choice</h2>
                    </div>
                </div>
                <>
                    {services &&
                        services.map((feature, i) => (
                            // <div
                            //     className="col-lg-4 items col-md-6 col-sm-6 col-12"
                            //     key={i}
                            // >
                            //     <div className="featured-wrap">
                            //         <div className="featured-img">
                            //             <img src={"https://source.unsplash.com/" + feature.image + "/640x350"} alt="" />
                            //             <div className="featured-content">
                            //                 <Link
                            //                     onClick={ClickHandler}
                            //                     to={feature.link || null}
                            //                 >
                            //                     {feature.name}
                            //                 </Link>
                            //             </div>
                            //         </div>
                            //     </div>
                            // </div>
                            <Destination3 service={feature} />
                        ))}
                </>
            </div>
        </div>
    );
};

export default Features;
